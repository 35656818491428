<template>
  <div>
    <span class="text-center table-edit-icon" @click="getPreview()">
      <i class="text-center material-icons">remove_red_eye</i>
    </span>

    <div class="demo-alignment">
      <vs-popup
        :id="'popmodalView' + params.data.employment_id"
        title="Employee Details"
        :active.sync="popupActive"
      >
        <div class="vx-col w-full">
          <!-- LEFT SIDE -->

          <div class="mx-0 row mb-6">
            <div class="col-12 col-md-6 col-sm-6">
              <div>
                <p class="text-muted small mb-1">Name:</p>
                <div class>
                  <h5>{{ this.form.user_name }}</h5>
                </div>
              </div>
            </div>

            <div class="col-12 col-md-6 col-sm-6">
              <div>
                <p class="text-muted small mb-1">Company Name:</p>
                <div class>
                  <h5>{{ this.form.company_name }}</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="mx-0 row mb-6">
            <div class="col-12 col-md-6 col-sm-6">
              <div>
                <p class="text-muted small mb-1">Department:</p>
                <div class>
                  <h5>{{ this.form.department_name }}</h5>
                </div>
              </div>
            </div>

            <div class="col-12 col-md-6 col-sm-6">
              <div>
                <p class="text-muted small mb-1">Designation:</p>
                <div class>
                  <h5>{{ this.form.designation_name }}</h5>
                </div>
              </div>
            </div>
          </div>

          <div class="mx-0 row mb-6">
            <div class="col-12 col-md-6 col-sm-6">
              <div>
                <p class="text-muted small mb-1">User Role:</p>
                <div class>
                  <h5>{{ this.form.role_name }}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import EmployeeService from "@/services/employeeService";
export default Vue.extend({
  data() {
    return {
      params: null,
      employment_id: null,
      employmentDetail: {},
      popupActive: false,
      //   project_id: ""
    };
  },

  beforeMount: function() {
    this.form = this.params.data;
  },
  beforeDestroy() {
    let _id = document.getElementById(
      "popmodalView" + this.params.data.employment_id
    );
    //console.log("_id", _id);
    _id.setAttribute("style", "display: none;");
  },
  methods: {
    getPreview: function() {
      this.employment_id = this.params.data.employment_id;
      this.popupActive = true;
      EmployeeService.getEmployee(
        this.employment_id,
        String(this.params.data.employment_active) === "4"
          ? "archive"
          : undefined
      )
        .then((response) => {
          const { data } = response;
          if (!data.err || data.Status == true) {
            this.employmentDetail = data.data;
            console.log("this.employmentDetail data.data :", data.data);
            this.form = this.employmentDetail;
          } else {
          }
        })
        .catch((error) => {
          console.log("error :", error);
        });
    },
  },
});
</script>

<style></style>
