<template>
  <div v-if="checkPermission(['employment', 'PUT'])">
    <span class="text-center table-edit-icon" @click="showModal">
      <i class="text-center material-icons">edit</i>
    </span>
    <div class="demo-alignment">
      <vs-popup
        :id="'popmodal' + params.data.employment_id"
        class="holamundo p-2 ScrollPopup"
        title="Update Employment"
        :active.sync="popupActive"
      >
        <form>
          <FocusLock>
            <div class="vx-row">
              <!-- LEFT SIDE -->
              <div class="col-12">
                <!-- COMPANY NAME -->
                <div class="vx-row mb-6">
                  <div
                    class="col-md-4 text-left md:text-right align-self-center"
                  >
                    <h5 class="mb-0">Company*</h5>
                  </div>
                  <div class="col-12 col-md-7">
                    <!-- Start ************* -->
                    <div>
                      <multiselect
                        v-model="company_value"
                        track-by="company_id"
                        label="company_name"
                        @input="
                          ({ company_id }) =>
                            (this.form.company_id = company_id)
                        "
                        :options="companies"
                        name="Company Name"
                        :searchable="true"
                        :deselectLabel="''"
                        :select-label="''"
                        :allow-empty="false"
                        open-direction="bottom"
                        deselect-label="Can't remove this value"
                        :disabled="true"
                      >
                        <template slot="singleLabel" slot-scope="{ option }">
                          {{ option.company_name }}
                        </template>
                      </multiselect>
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('Company Name')"
                        >{{ errors.first("Company Name") }}</span
                      >
                    </div>
                    <!-- ************* End -->
                  </div>
                </div>

                <!-- DEPARTMENT DROPDOWN -->

                <div class="vx-row mb-6">
                  <div
                    class="col-md-4 text-left md:text-right align-self-center"
                  >
                    <h5 class="mb-0">Department*</h5>
                  </div>
                  <div class="col-12 col-md-7">
                    <!-- Start ************* -->
                    <div>
                      <multiselect
                        v-model="department_value"
                        track-by="department_id"
                        label="department_name"
                        @input="
                          ({ department_id }) =>
                            (this.form.department_id = department_id)
                        "
                        :options="departments"
                        name="Department Name"
                        :searchable="true"
                        :deselectLabel="''"
                        :select-label="''"
                        :allow-empty="false"
                        open-direction="bottom"
                        deselect-label="Can't remove this value"
                      >
                        <template slot="singleLabel" slot-scope="{ option }">
                          {{ option.department_name }}
                        </template>
                      </multiselect>
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('Department Name')"
                        >{{ errors.first("Department Name") }}</span
                      >
                    </div>
                    <!-- ************* End -->
                  </div>
                </div>

                <!-- DESIGNATION DROPDOWN -->
                <div class="vx-row mb-6">
                  <div
                    class="col-md-4 text-left md:text-right align-self-center"
                  >
                    <h5 class="mb-0">Designation*</h5>
                  </div>
                  <div class="col-12 col-md-7">
                    <!-- Start ************* -->
                    <div>
                      <multiselect
                        v-model="designation_value"
                        track-by="designation_id"
                        label="designation_name"
                        @input="
                          ({ designation_id }) =>
                            (this.form.designation_id = designation_id)
                        "
                        :options="designations"
                        name="Designation Name"
                        :searchable="true"
                        :deselectLabel="''"
                        :select-label="''"
                        :allow-empty="false"
                        open-direction="bottom"
                        deselect-label="Can't remove this value"
                      >
                        <template slot="singleLabel" slot-scope="{ option }">
                          {{ option.designation_name }}
                        </template>
                      </multiselect>
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('Designation Name')"
                        >{{ errors.first("Designation Name") }}</span
                      >
                    </div>
                    <!-- ************* End -->
                  </div>
                </div>
              </div>
            </div>

            <!-- SUBMIT BUTTON -->
            <div class="vx-row">
              <div class="vx-col mx-auto">
                <vs-button
                  :disabled="submitStatus"
                  type="filled"
                  @click.prevent="submitForm()"
                  class="mr-3"
                  >Update</vs-button
                >

                <!-- <vs-button color="warning" type="border" class @click.prevent="clearForm">Reset</vs-button> -->
              </div>
            </div>
          </FocusLock>
        </form>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import employeeService from "@/services/employeeService.js";
import CompanyService from "@/services/companyService.js";
import departmentService from "@/services/departmentService.js";
import designationService from "@/services/designationService.js";
export default Vue.extend({
  data() {
    return {
      switch1: true,
      submitStatus: true,
      form: {
        company_id: "",
        department_id: "",
        designation_id: "",
        user_id: "",
        employment_active: 1,
        // organization_id: null,
      },
      companies: [],
      company_value: [],
      designation_value: [],
      department_value: [],
      departments: [],
      designations: [],
      users: [],
      popupActive: false,
      params: null,
      value: null,
    };
  },
  watch: {
    form: {
      handler: function(newVal, oldVal) {
        const values = newVal;
        let count = 0;
        let array = [
          "company_id",
          "department_id",
          "designation_id",
          "user_id",
        ];
        array.map((x) => {
          if (String(values[x]).length > 0) {
            count++;
          }
          count === array.length
            ? (this.submitStatus = false)
            : (this.submitStatus = true);
        });
      },
      deep: true,
    },
  },
  beforeMount() {
    this.value = this.params.value;
    this.form = this.params.data;
  },
  beforeDestroy() {
    let _id = document.getElementById(
      "popmodal" + this.params.data.employment_id
    );
    _id.setAttribute("style", "display: none;");
  },
  methods: {
    showModal() {
      this.popupActive = true;
      this.submitStatus = true;
      this.getCompanyList();
      this.getDepartmentList(this.params.data.company_id);
      this.getDesignationList(this.params.data.company_id);
    },
    submitForm() {
      this.$validator.validateAll().then((result) => {
        this.$vs.loading();
        if (result) {
          this.submitStatus = true;
          let id = this.params.data.employment_id;
          let payload = {
            ...this.form,
            // company_id: this.form.company_id,
            // department_id: this.form.department_id,
            // designation_id: this.form.designation_id,
            // user_id: this.form.user_id,
            // employment_active: this.form.employment_active
          };
          // console.log(
          //   "payload :",
          //   this.params.data.designation_id,
          //   payload,
          // );
          employeeService
            .editEmployee(payload, id)
            .then((response) => {
              this.$vs.loading.close();
              this.submitStatus = false;
              const { data } = response;
              this.popupActive = false;
              if (data.Status == true) {
                console.log(data, "iouiuo");
                this.$vs.notify({
                  title: "Updated!",
                  text: data.Message,

                  iconPack: "feather",
                  icon: "check_box",
                  color: "success",
                });
                setTimeout(() => {
                  eventBus.$emit("refreshempTable", data);
                }, 1);
                this.clearForm();
              } else {
                this.$vs.loading.close();
                console.log(data, "88999");
                this.$vs.notify({
                  title: "Error!",
                  text: data.Message,

                  iconPack: "feather",
                  icon: "check_box",
                  color: "warning",
                });
              }
            })
            .catch((error) => {
              this.$vs.loading.close();
              this.submitStatus = true;
              console.log("error :", error);
            });
          // if form have no errors
          // alert("form submitted!");
        } else {
          // form have errors
        }
      });
    },
    // COMPANY LIST
    getCompanyList: function() {
      CompanyService.getAllCompanies()
        .then((response) => {
          const { data } = response;
          if (data.err || data.Status == false) {
          } else {
            this.companies = data.data;
            let SelectedCompanyDropdown = this.companies.filter(
              ({ company_id }) =>
                String(company_id) === String(this.form.company_id)
            );
            this.company_value =
              SelectedCompanyDropdown.length > 0
                ? SelectedCompanyDropdown[0]
                : [];
          }
        })
        .catch((error) => {
          console.log("error :", error);
        });
    },
    // DEPARTMENT LIST
    getDepartmentList: function(company_id) {
      departmentService
        .getAllDepartmentsByCompany(company_id)
        .then((response) => {
          const { data } = response;
          if (data.err || data.Status == false) {
          } else {
            this.departments = data.data;
            let SelectedDepartmentDropdown = this.departments.filter(
              ({ department_id }) =>
                String(department_id) === String(this.form.department_id)
            );
            this.department_value =
              SelectedDepartmentDropdown.length > 0
                ? SelectedDepartmentDropdown[0]
                : [];
          }
        })
        .catch((error) => {
          console.log("error :", error);
        });
    },
    //  DESIGNATION LIST
    getDesignationList: function(company_id) {
      designationService
        .getAllDesignationByCompany(company_id)
        .then((response) => {
          const { data } = response;
          if (data.err) {
          } else {
            this.designations = data.data;
            let SelectedDesignationDropdown = this.designations.filter(
              ({ designation_id }) =>
                String(designation_id) === String(this.form.designation_id)
            );
            this.designation_value =
              SelectedDesignationDropdown.length > 0
                ? SelectedDesignationDropdown[0]
                : [];
          }
        })
        .catch((error) => {
          console.log("error :", error);
        });
    },
    //  USER LIST
    // getUserList: function() {
    //   UserService.UserList()
    //     .then(response => {
    //       const { data } = response;
    //       if (data.err) {
    //       } else {
    //         this.users = data.data;
    //       }
    //     })
    //     .catch(error => {
    //       console.log("error :", error);
    //     });
    // },
    clearForm: function() {
      Object.keys(this.form).map((x, index) => {
        /_active/.test(x) ? (this.form[x] = "1") : (this.form[x] = "");
      });
      this.$validator.reset();
    },
  },

  mounted() {
    // this.getUserList();
  },
});
</script>
<style></style>
