<!-- =========================================================================================
    File Name: AgGridTable.vue
    Description: Ag Grid table
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
    Author:
    Author URL:
========================================================================================== -->

<template>
  <div>
    <!-- DEPARTMENTS LIST -->
    <div id="ag-grid-demo">
      <vx-card>
        <!-- TABLE ACTION ROW -->
        <div class="flex flex-wrap justify-between items-center form-title">
          <h4>Employees</h4>
          <div class="flex flex-wrap justify-end items-center">
            <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
            <div
              class="
                flex flex-wrap
                justify-between
                ag-grid-table-actions-right
              "
            >
              <vs-input
                class="mb-4 md:mb-0 mr-0 sm:mr-4 w-full sm:w-auto"
                v-model="searchQuery"
                @input="updateSearchQuery"
                placeholder="Search..."
              />
              <!-- <vs-button
                class="mb-4 md:mb-0 text-hover-white"
                @click="gridApi.exportDataAsCsv()"
              >Export as CSV</vs-button>-->
              <vx-tooltip text="Export Excel" class="w-full sm:w-auto">
                <a
                  :href="
                    excelurl +
                      `/v2/employment/excel/download?organization_id=${organization_id}&token=${temp_token}&search=${searchQuery}`
                  "
                  target="_blank"
                  v-if="this.archive != 'archive'"
                >
                  <vs-button class="px-4 w-full sm:w-auto">
                    <span class="d-inline-flex pr-5 text-white">Export</span>
                    <img
                      src="@/assets/images/icons/export-excel.svg"
                      class="d-inline-flex"
                      height="20"
                      width="20"
                    />
                  </vs-button>
                </a>
              </vx-tooltip>

              <vx-tooltip
                color="primary"
                text="Export Archive Excel"
                class="w-full sm:w-auto"
              >
                <a
                  :href="
                    excelurl +
                      `/v2/employment/excel/download?organization_id=${organization_id}&token=${temp_token}&archive=1&search=${searchQuery}`
                  "
                  target="_blank"
                  v-if="this.archive == 'archive'"
                >
                  <vs-button class="px-4 w-full sm:w-auto">
                    <span class="d-inline-flex pr-5">Export</span>

                    <img
                      src="@/assets/images/icons/export-excel.svg"
                      class="d-inline-flex"
                      height="20"
                      width="20"
                    />
                  </vs-button>
                </a>
              </vx-tooltip>
            </div>
          </div>
        </div>
        <ag-grid-vue
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowClicked="onRowDataChanged"
          :rowData="employees"
          rowSelection="multiple"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="false"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
        ></ag-grid-vue>
        <vs-pagination
          :total="totalPages"
          :max="maxPageNumbers"
          v-model="currentPage"
        />
      </vx-card>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import contacts from "@/views/ui-elements/ag-grid-table/data.json";
import DatetimePickerStartEnd from "@/views/components/extra-components/datetime-picker/DatetimePickerStartEnd.vue";
import SearchService from "@/services/searchService.js";
import ContractStatus from "@/views/apps/masters/employment/ContractStatus.vue";
import EmploymentStatus from "@/views/apps/masters/employment/EmploymentStatus.vue";
import EmployArchive from "@/views/apps/masters/employment/EmployArchive.vue";
import EditEmployment from "@/views/apps/masters/employment/EditEmployment.vue";
import EmploymentDetails from "@/views/apps/masters/employment/EmploymentDetails.vue";
import employeeService from "@/services/employeeService.js";
import { BASEURL } from "@/config/index.js";
import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";
import FocusLock from "@/components/FocusLock.vue";
const VxTour = () => import("@/components/VxTour.vue");

export default {
  components: {
    FocusLock,
    AgGridVue,
    VxTour,
    DatetimePickerStartEnd,
    "switch-component": {
      template: `<span>sjd</span>`,
    },
  },
  props: ["listType"],
  data() {
    return {
      excelurl: BASEURL,
      archive: this.listType,
      employees: [],
      User: "Select User",
      switchValue: true,
      searchQuery: "",
      totalPages: 0,
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true,
      },
      fromDate: null,
      toDate: null,
      configFromdateTimePicker: {
        minDate: new Date(),
        maxDate: null,
      },
      configTodateTimePicker: {
        minDate: null,
      },
      cityOptions: [
        { text: "New York", value: "new-york" },
        { text: "Chicago", value: "chicago" },
        { text: "San Francisco", value: "san-francisco" },
        { text: "Boston", value: "boston" },
      ],
      contacts: contacts,
      columnDefs: [],
      organization_id: "",
    };
  },
  beforeMount() {
    this.organization_id = localStorage.getItem("OrganizationID");
    eventBus.$on("refreshempTable", (e) => {
      this.getEmployeeList(this.currentPage);
    });

    this.columnDefs = [
      {
        headerName: "User",
        field: "user_name",
        width: 200,
        suppressSizeToFit: true,
      },
      {
        headerName: "Company",
        field: "company_name",
        width: 225,
      },
      {
        headerName: "Employment Status",
        field: "",
        width: 95,
        sortable: false,
        hide:
          this.archive == "archive" || this.checkUserType == 1 ? true : false,
        cellStyle: { "text-align": "center" },
        cellRendererFramework: EmploymentStatus,
      },
      {
        headerName: "Contract Status",
        field: "",
        width: 95,
        sortable: false,
        hide:
          this.archive == "archive" || this.checkUserType == 0 ? true : false,
        cellStyle: { "text-align": "center" },
        cellRendererFramework: ContractStatus,
      },
      {
        headerName: "Details",
        field: "employment_id",
        width: 100,
        sortable: false,
        cellStyle: { "text-align": "center" },
        cellRendererFramework: EmploymentDetails,
      },
      {
        headerName: "Edit",
        field: "",
        width: 80,
        sortable: false,
        hide: this.archive == "archive" ? true : false,
        cellStyle: { "text-align": "center" },
        cellRendererFramework: EditEmployment,
      },
      {
        headerName: this.archive == "archive" ? "Unarchive" : "Archive",
        field: "",
        sortable: false,
        width: this.archive ? 140 : 104,
        cellStyle: { "text-align": "center" },
        cellRendererFramework: EmployArchive,
      },
    ];
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("email", null);
      } else this.gridOptions.columnApi.setColumnPinned("email", "left");
    },
  },
  computed: {
    checkUserType() {
      return window.localStorage.getItem("UserType");
    },
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 100;
    },
    // totalPages() {
    //   if (this.gridApi) return this.gridApi.paginationGetTotalPages();
    //   else return 0;
    // },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      // set(val) {
      //   if (this.gridApi) return this.gridApi.paginationGoToPage(val - 1);
      //   else return 1;
      // }
      set(val) {
        if (val) {
          this.getEmployeeList(val);
          return val;
        } else return 1;
      },
    },
  },
  methods: {
    onRowDataChanged(e) {
      console.log("e :", e);
    },
    onChangeStatus: function() {
      console.log("object");
    },
    // updateSearchQuery(val) {
    //   this.gridApi.setQuickFilter(val);
    // },
    updateSearchQuery(val) {
      let bol = this.archive == "archive" ? true : false;
      SearchService.getAllSerchResultV2(val, "employment", null, bol)
        .then((response) => {
          const { data } = response;
          if (data.data.length > 0 && data.Status) {
            this.employees = data.data;
          } else {
            this.employees = [];
            // this.$vs.notify({
            //   title: "Search data not found",
            //   text: data.Message,
            //   iconPack: "feather",
            //   icon: "check_box",
            //   color: "warning"
            // });
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Data Not Found",
            text: error.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    onFromChange(selectedDates, dateStr, instance) {
      this.$set(this.configTodateTimePicker, "minDate", dateStr);
    },
    onToChange(selectedDates, dateStr, instance) {
      this.$set(this.configFromdateTimePicker, "maxDate", dateStr);
    },
    getEmployeeList: function(currentPage) {
      let filter = {
        // limit: this.paginationPageSize
        page: currentPage,
      };
      console.log("fsdfdsfdsfdsf");

      if (this.archive == "archive") {
        filter["archive"] = true;
      }
      employeeService
        .getAllEmployees(filter)
        .then((response) => {
          const { data } = response;
          if (data.err || data.Status == false) {
          } else {
            this.employees = data.data;
            this.totalPages = data["pagination"]
              ? data.pagination.last_page
              : 1;
            this.gridApi.sizeColumnsToFit();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    if (this.checkPermission(["employment", "GET"])) {
      this.getEmployeeList(this.currentPage);
    }
    this.gridApi = this.gridOptions.api;
  },
};
</script>
