var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col w-full"},[_c('vx-card',{staticClass:"form-title",attrs:{"title":"Add Employment"}},[_c('form',[_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col md:w-3/4 mx-auto"},[_c('div',{staticClass:"vx-row mb-6"},[_c('div',{staticClass:"vx-col sm:w-1/3 w-full text-left sm:text-right align-self-center"},[_c('h6',{staticClass:"mb-0"},[_vm._v("User*")])]),_c('div',{staticClass:"vx-col sm:w-2/3 w-full"},[_c('div',[_c('multiselect',{attrs:{"track-by":"user_id","label":"user_name","placeholder":"Select User","options":_vm.users,"name":"User Name","searchable":true,"allow-empty":false,"open-direction":"bottom","deselect-label":"Can't remove this value"},on:{"input":function (ref) {
                          var user_id = ref.user_id;

                          return (this$1.form.user_id = user_id);
}},scopedSlots:_vm._u([{key:"singleLabel",fn:function(ref){
var option = ref.option;
return [_vm._v("\n                        "+_vm._s(option.user_name)+"\n                      ")]}}]),model:{value:(_vm.user_value),callback:function ($$v) {_vm.user_value=$$v},expression:"user_value"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('User Name')),expression:"errors.has('User Name')"}],staticClass:"text-danger text-sm"},[_vm._v(_vm._s(_vm.errors.first("User Name")))])],1)])]),_c('div',{staticClass:"vx-row mb-6"},[_c('div',{staticClass:"vx-col sm:w-1/3 w-full text-left sm:text-right align-self-center"},[_c('h6',{staticClass:"mb-0"},[_vm._v("Company*")])]),_c('div',{staticClass:"vx-col sm:w-2/3 w-full"},[_c('div',[_c('multiselect',{attrs:{"track-by":"company_id","label":"company_name","placeholder":"Select Company","options":_vm.companies,"name":"Company Name","searchable":true,"allow-empty":false,"open-direction":"bottom","deselect-label":"Can't remove this value"},on:{"input":function (ref) {
                                                    var company_id = ref.company_id;

                                                    return (this$1.form.company_id = company_id);
},"select":_vm.onChangeCompany},scopedSlots:_vm._u([{key:"singleLabel",fn:function(ref){
                          var option = ref.option;
return [_vm._v("\n                        "+_vm._s(option.company_name)+"\n                      ")]}}]),model:{value:(_vm.company_value),callback:function ($$v) {_vm.company_value=$$v},expression:"company_value"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('Company Name')),expression:"errors.has('Company Name')"}],staticClass:"text-danger text-sm"},[_vm._v(_vm._s(_vm.errors.first("Company Name")))])],1)])]),_c('div',{staticClass:"vx-row mb-6"},[_c('div',{staticClass:"vx-col sm:w-1/3 w-full text-left sm:text-right align-self-center"},[_c('h6',{staticClass:"mb-0"},[_vm._v("Department*")])]),_c('div',{staticClass:"vx-col sm:w-2/3 w-full"},[_c('div',[_c('multiselect',{attrs:{"track-by":"department_id","label":"department_name","placeholder":"Select Department","options":_vm.departments,"name":"Department Name","searchable":true,"allow-empty":false,"open-direction":"bottom","deselect-label":"Can't remove this value"},on:{"input":function (ref) {
                                                    var department_id = ref.department_id;

                                                    return (this$1.form.department_id = department_id);
}},scopedSlots:_vm._u([{key:"singleLabel",fn:function(ref){
                          var option = ref.option;
return [_vm._v("\n                        "+_vm._s(option.department_name)+"\n                      ")]}}]),model:{value:(_vm.department_value),callback:function ($$v) {_vm.department_value=$$v},expression:"department_value"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('Department Name')),expression:"errors.has('Department Name')"}],staticClass:"text-danger text-sm"},[_vm._v(_vm._s(_vm.errors.first("Department Name")))])],1)])]),_c('div',{staticClass:"vx-row mb-6"},[_c('div',{staticClass:"vx-col sm:w-1/3 w-full  text-left sm:text-right align-self-center"},[_c('h6',{staticClass:"mb-0"},[_vm._v("Designation*")])]),_c('div',{staticClass:"vx-col sm:w-2/3 w-full"},[_c('div',[_c('multiselect',{attrs:{"track-by":"designation_id","label":"designation_name","placeholder":"Select Designation","options":_vm.designations,"name":"Designation Name","searchable":true,"allow-empty":false,"open-direction":"bottom","deselect-label":"Can't remove this value"},on:{"input":function (ref) {
                                                    var designation_id = ref.designation_id;

                                                    return (this$1.form.designation_id = designation_id);
}},scopedSlots:_vm._u([{key:"singleLabel",fn:function(ref){
                          var option = ref.option;
return [_vm._v("\n                        "+_vm._s(option.designation_name)+"\n                      ")]}}]),model:{value:(_vm.designation_value),callback:function ($$v) {_vm.designation_value=$$v},expression:"designation_value"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('Designation Name')),expression:"errors.has('Designation Name')"}],staticClass:"text-danger text-sm"},[_vm._v(_vm._s(_vm.errors.first("Designation Name")))])],1)])]),_c('div',{staticClass:"vx-row mb-6"},[_c('div',{staticClass:"vx-col sm:w-1/3 w-full text-left sm:text-right "},[_c('h6',{staticClass:"mb-0"},[_vm._v("Status*")])]),_c('div',{staticClass:"vx-col sm:w-2/3 w-full"},[_c('vs-switch',{model:{value:(_vm.switch1),callback:function ($$v) {_vm.switch1=$$v},expression:"switch1"}})],1)])])]),_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col mx-auto"},[_c('vs-button',{staticClass:"mr-3 mb-3 w-full sm:w-auto",attrs:{"disabled":_vm.submitStatus,"type":"filled"},on:{"click":function($event){$event.preventDefault();return _vm.submitForm($event)}}},[_vm._v("Submit")]),_c('vs-button',{staticClass:"mb-3 w-full sm:w-auto",attrs:{"color":"warning","type":"border"},on:{"click":function($event){$event.preventDefault();return _vm.clearForm($event)}}},[_vm._v("Reset")])],1)])])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }